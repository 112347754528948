// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import $ from 'jquery';
require("@popperjs/core");
require("bootstrap");
import 'select2';

$(document).ready(function() {

  // Setup Select2 on fields
  $('select').select2({
    minimumResultsForSearch: -1
  });

  // Auto hide Alerts after 7 seconds
  setTimeout(function() {
    $(".alert").fadeTo(500, 0).slideUp(500, function(){
      $(this).remove();
    });
  }, 7000);

  // Hide / Unhide fields based on Opt Out checkbox
  $('#ie_score_opt_out, #ec_score_opt_out').change(function() {
    if($(this).is(':checked')) {
      $('.field').show();
      $('.field').not('.dont-hide').hide();
    } else {
      $('.field').show();
    }
  });

});
